import Modal from '../Modal'
import Template from '../Template'
import * as style from './style.module.scss'
import axios from 'axios'
import Link from 'gatsby-link'
import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'

function DoubleConfirm(props) {
  const doubleConfirm = useDialogState({ baseId: 'doubleConfirm' })
  const { onClick, ...form } = props

  return (
    <>
      <DialogDisclosure
        className={style.buttonSubmit}
        {...doubleConfirm}
        onClick={onClick}
      >
        Confirm application details
      </DialogDisclosure>
      <Modal {...doubleConfirm}>
        <div className={style.modal}>
          <div className={style.title}>
            <h2>Confirm details</h2>
            <button
              className={style.buttonClose}
              onClick={() => doubleConfirm.hide()}
            >
              Back
            </button>
          </div>
          <p>
            By submitting this application, you confirm this information is true
            and is being submitted by the person named below:
          </p>
          <div className={style.modalSummary}>
            <div className={style.summaryGroup}>
              <div className={style.label}>First and last name</div>
              <div className={`${style.value} ${style.capitalized}`}>
                {props.values.sitterFirstName} {props.values.sitterLastName}
              </div>
            </div>
            <div className={style.summaryGroup}>
              <div className={style.label}>Email address</div>
              <div className={style.value}>{props.values.email}</div>
            </div>
            <div className={style.summaryGroup}>
              <div className={style.label}>Phone number</div>
              <div className={style.value}>{props.values.phone}</div>
            </div>
            <div className={style.summaryGroup}>
              <div className={style.label}>Address where you'll be hosting</div>
              <div className={`${style.value} ${style.capitalized}`}>
                {props.values.streetAddress} {props.values.city},{' '}
                {props.values.state}
              </div>
            </div>
          </div>

          <Form {...form} className={style.buttonContainer}>
            <FormSubmitButton type='submit' className={style.buttonSubmit}>
              Submit my application for review
            </FormSubmitButton>
          </Form>
        </div>
      </Modal>
    </>
  )
}

function PageSitterApplication(props) {
  const [submitted, setSubmitted] = useState(false)
  const form = useFormState({
    values: {
      email: '',
      phone: '',
      sitterFirstName: '',
      sitterLastName: '',
      businessDescription: '',
      city: '',
      state: '',
      streetAddress: '',
      accomodationType: '',
    },
    onSubmit: async (values) => {
      let geoLocation
      if (values?.streetAddress) {
        geoLocation = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${values.streetAddress}.json?country=us&limit=1&proximity=ip&autocomplete=false&access_token=pk.eyJ1Ijoid29rZWphY3F1ZWxpbmUiLCJhIjoiY2w0NW8wMHN5MDBmdzNlbzBzZDRkOHJ0dCJ9.zbX5Gz2z-8JouldxtoG84g`
        )
      }
      let businessDescriptionEdit = ''
      if (values?.businessDescription) {
        //Escape Strings, then remove white space if its there.
        businessDescriptionEdit = values.businessDescription
          .replace(/\\/g, '\\\\')
          .replace(/\"/g, '\\"')
          .replace(/[\r\n]/gm, '')
      }
      const id = values.sitterFirstName.split(' ').join('-').toLowerCase()
      const formattedText = `export const frontmatter = {
  meta: { 
    title: "${values.sitterFirstName}",
    description:"${values?.businessDescription ? businessDescriptionEdit : ''}",
  },
  categories: [''],
  city: '${values.city ?? null}',
  date: '${new Date().toISOString()}',
  email: '${values.email}',
  id: '${id}',
  icon: '../placeholder.jpg',
  image: '../placeholder.jpg',
  tags: [''],
  tags_private: [''],
  _geoloc: {
    lat: ${geoLocation?.data.features[0].geometry.coordinates[1] ?? 0},
    lng:  ${geoLocation?.data.features[0].geometry.coordinates[0] ?? 0},
  },
  phone: '${values.phone ?? null}',
  postTitle: "${values.sitterFirstName}",
  profileData: {
    accomodationType: '${values.accomodationType ?? null}',
    sitterFirstName: '${values.sitterFirstName ?? null}',
    sitterLastName: '${values.sitterLastName ?? null}',  
  }
  state: '${values.state ?? null}',
  streetAddress: '${values?.streetAddress ?? null}',
  website: '',
}

${values.businessDescription}`

      const formData = { ...values, id, formattedText }

      document.body.scrollTop = 0
      setSubmitted(true)

      await fetch('/api/sitter-application', {
        method: 'POST',
        body: JSON.stringify(formData),
      })
    },
  })

  return (
    <Template>
      <Helmet>
        <title>
          Apply for a business account - {props.data.site.siteMetadata.title}
        </title>
      </Helmet>
      {submitted && (
        <div className={style.fadeIn}>
          <div className={style.hero}>
            <h1>Thank you!</h1>
            <p>Your application has been sent.</p>
          </div>
          <div className={style.resultBox}>
            <h3>What happens next?</h3>
            <p>
              After your application is reviewed, you should receive an email
              from doggos.com to proceed to the Sitter Interview.
            </p>
            <h4>How long does the review process take?</h4>
            <p>
              Usually 3 days or less. During periods of very high submissions,
              responses can take as long as 1 week.
            </p>
            <h4>What is a "Sitter Interview?"</h4>
            <p>
              The sitter interview is a 30 minute video call with a member of
              the doggos.com team. This is part of our normal onboarding process
              for all sitters, and helps to keep our community safe.
            </p>
            <p>
              After the interview, you'll receive a link to proceed to the
              background check, which is the final step in our onboarding
              process.
            </p>
            <h4>How long do background checks take?</h4>
            <p>
              Background checks are provided by a third-party expert,{' '}
              <a
                href='https://checkr.com/our-technology/background-check-api'
                target='_blank'
                rel='noreferrer'
              >
                Checkr
              </a>
              , and are usually completed in 24 hours or less.
            </p>
            <h4>Helpful resources</h4>
            <p>
              While you wait for your application to be processed, check out
              these helpful resources for being successful on doggos.com:
            </p>
            <ul>
              <li>
                <a href={'/getting-started-as-a-dog-sitter'} target='_blank'>
                  Getting started as a dog sitter on doggos.com
                </a>
              </li>
              <li>
                <a href={'/editing-your-dog-sitter-profile'} target='_blank'>
                  Editing your profile
                </a>
              </li>
              <li>
                <a
                  href={
                    '/how-do-refunds-and-cancellations-work-for-dog-sitters'
                  }
                  target='_blank'
                >
                  How do refunds work?
                </a>
              </li>
              <li>
                <a
                  href={'/how-do-payments-work-for-dog-sitters'}
                  target='_blank'
                >
                  How do payments work?
                </a>
              </li>
            </ul>
            <p>
              Thank you for taking the time to apply! We look forward to having
              you as a user and a member of our community.
            </p>
          </div>
        </div>
      )}
      {/* Form */}
      {!submitted && (
        <>
          <div className={style.hero}>
            <h1>Apply for a professional account</h1>
            <p>
              Thank you for your interest in becoming a dog sitter! To get
              started, please tell us a little about yourself.
            </p>
          </div>
          <Form
            {...form}
            name='sitterApplication'
            noValidate={false}
            className={style.form}
          >
            <div className={style.sitterApplicationHeader}>
              <h3>Get started</h3>
            </div>

            <div className={style.formRow}>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='email'>
                  Your email address <span className={style.required}>*</span>
                </FormLabel>
                <FormInput
                  className={style.input}
                  {...form}
                  type='email'
                  name='email'
                  required
                />
              </div>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='phone'>
                  Your phone number <span className={style.required}>*</span>
                </FormLabel>
                <FormInput
                  className={style.input}
                  {...form}
                  type='tel'
                  name='phone'
                  placeholder='(702) 555-5555'
                  required
                />
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='sitterFirstName'>
                  First name <span className={style.required}>*</span>
                </FormLabel>
                <FormInput
                  className={style.input}
                  {...form}
                  type='text'
                  name='sitterFirstName'
                  pattern='[A-Za-z0-9]+'
                  title='Spaces and punctuation are not allowed'
                  required
                />
              </div>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='sitterLastName'>
                  Last name <span className={style.required}>*</span>
                </FormLabel>
                <FormInput
                  className={style.input}
                  {...form}
                  type='text'
                  name='sitterLastName'
                  pattern='[A-Za-z0-9]+'
                  title='Spaces and punctuation are not allowed'
                  required
                />
              </div>
            </div>

            <div className={style.formRow}>
              {typeof window !== 'undefined' && (
                <React.Suspense fallback={null}>
                  <div className={style.inputGroup}>
                    <FormLabel {...form} htmlFor='streetAddress'>
                      Address where you'll be hosting{' '}
                      <span className={style.required}>*</span>
                    </FormLabel>
                    <div className={style.geoapifyInput}>
                      <FormInput
                        className={style.input}
                        {...form}
                        name='streetAddress'
                        placeholder='Street address'
                      />
                    </div>
                  </div>
                  <div className={style.inputGroup}>
                    <FormLabel {...form} htmlFor='city'>
                      City <span className={style.required}>*</span>
                    </FormLabel>
                    <div className={style.geoapifyInput}>
                      <FormInput {...form} name='city' placeholder='City' />
                    </div>
                  </div>
                  <div className={style.inputGroup}>
                    <FormLabel {...form} htmlFor='state'>
                      State <span className={style.required}>*</span>
                    </FormLabel>
                    <div className={style.geoapifyInput}>
                      <FormInput name='state' placeholder='State' {...form} />
                    </div>
                  </div>
                </React.Suspense>
              )}
            </div>
            <div className={style.formRow}>
              <div className={style.inputGroup}>
                <FormLabel {...form} name='accomodationType'>
                  Where will you be providing dog sitting services?{' '}
                </FormLabel>

                <FormRadioGroup
                  {...form}
                  name='accomodationType'
                  className={style.radioContainer}
                >
                  <label className={style.radioGroup}>
                    <FormRadio
                      className={style.radio}
                      {...form}
                      name='accomodationType'
                      value='home'
                    />{' '}
                    <div className={style.radioLabel}>Home</div>
                  </label>
                  <label className={style.radioGroup}>
                    <FormRadio
                      className={style.radio}
                      {...form}
                      name='accomodationType'
                      value='apartment'
                    />{' '}
                    <div className={style.radioLabel}>Apartment or condo</div>
                  </label>
                  <label className={style.radioGroup}>
                    <FormRadio
                      className={style.radio}
                      {...form}
                      name='accomodationType'
                      value='townhome'
                    />
                    <div className={style.radioLabel}>Townhome</div>
                  </label>
                  <label className={style.radioGroup}>
                    <FormRadio
                      className={style.radio}
                      {...form}
                      name='accomodationType'
                      value='Business'
                    />
                    <div className={style.radioLabel}>Business</div>
                  </label>
                </FormRadioGroup>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.inputGroup}>
                <FormLabel {...form} htmlFor='businessDescription'>
                  What is your experience with dogs?{' '}
                  <span className={style.note}>
                    (Please include at least 50-70 words)
                  </span>
                  <span className={style.required}>*</span>
                </FormLabel>
                <FormInput
                  className={style.textarea}
                  {...form}
                  as='textarea'
                  name='businessDescription'
                  minLength={'50'}
                  required
                />
              </div>
            </div>
            <DoubleConfirm
              {...form}
              onClick={(event) => {
                if (!window.document.forms.sitterApplication.reportValidity()) {
                  event.preventDefault()
                }
              }}
            />
          </Form>
        </>
      )}
    </Template>
  )
}

export default PageSitterApplication
